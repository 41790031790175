import { useEffect, useState } from "react";
import TextOnly from "../Components/StreamingComponents/TextOnly";
import SpecificProductDetails from "../Components/StreamingComponents/SpecificProductDetails";
import BrandingCard from "../Components/StreamingComponents/BrandingCard";
import SingleCategoryList from "../Components/StreamingComponents/SingleCategoryList";
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from "lodash";
import { apiError, conversationList, storeChat } from "../Services/Redux/Reducers/chatSlice";
import { addchat, deleteChat } from "../Services/ApiServices/chatServices";
import { loader } from "../Services/Redux/Reducers/commonSlice";
import MultiCatTemplate from "../Components/MultiCatCardTemplate/MultiCatTemplate";
import ApplicationFeedback from "./ApplicationFeedback";


function MessageHistory(props) {
  const typewritterdelay = process.env.TYPE_WRITER_DELAY || 10
  const conList = useSelector((state) => state.chat.conversationList);
  const loading = useSelector((state) => state.common.isLoader)
  const dispatch = useDispatch();
  const isStore = useSelector((state) => state.chat.storeChat)
  const userDetails = useSelector((state) => state.common.loggedUserDetails)
  const stopStreaming = useSelector((state) => state.common.stopStreaming)
  const feedBackModal = useSelector((state) => state.common.appFeedBackModal)
  const [selectedIds, setSelectedIds] = useState([]);

  const storeMessage = (conHis) => {
    //return
    let convo = cloneDeep(conHis)
    let convoList = cloneDeep(conHis)
    let lastItem = convo.pop();
    const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    if(stopStreaming){

      addchat(lastItem,(response) => { // Chat History storing the messages     
        convoList[conIndex]['id'] = response.data.id   
        convoList[conIndex]['showLikeDislike'] = true
        convoList[conIndex]['error'] = ""
        dispatch(conversationList(convoList));
      }, (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      });
    }
  }

  useEffect(() => {
    if(!loading && isStore){
      let conHis = cloneDeep(conList)
      storeMessage(conHis)
      dispatch(storeChat(false));
    } else {
      dispatch(storeChat(false))
    }
  }, [loading, isStore])

  const handleValueChange = (childValue) => {
    props.onValueChange(childValue)
  };

  const selectMessages = (e) => {
    let selectids = selectedIds
    if(!selectids.includes(e.target.id)) {
      selectids.push(e.target.id)
    } else {
      const index = selectids.indexOf(e.target.id);
      if (index > -1) { 
        selectids.splice(index, 1); 
      }
      setSelectedIds(selectids)
    }
  }

  const deleteChats = () => {
    let responseStatus = {
      chatIds: selectedIds,
      userId: userDetails.userId,
    }
    dispatch(loader(true))
    dispatch(storeChat(false))
    deleteChat(responseStatus,(response) => {
      dispatch(loader(false))
      dispatch(storeChat(false))
    }, (err) => {
      dispatch(loader(false))
      dispatch(storeChat(false))
    });
  }

  return (
    <div className="messageHistory">
      {/* deleteChat api call */}
      {/* <div>
        <button className="btn btn-primary" onClick={() => deleteChats()}>Delete</button>
      </div> */}
      {conList &&
        conList.map((con, index) => (
          <div key={"history"+index} >
            <div>
              {/* <input type="checkbox" id={con.id} key={"history"+index} onClick={(e) => selectMessages(e)}/> */}
              <div className={`chat_msg ms-auto me-3 me-sm-1 fadeOutPromts`}>
                  <div className="d-flex align-items-start ps-0">
                      {/* <div className="border border-1 user_bubble">
                      {props.shortName}
                      </div> */}
                      <span className="badge-text">{con.question}</span>
                  </div>
              </div>
            </div>
            {
              Object.keys(con.response).length > 0 && 
                <div>
                  {
                    con && con.response?.responseType === "Error" && con.response?.error !== "Oops, Request cancelled" &&
                      <div className="text-danger">
                        {con.response.error}
                      </div>
                  }
                  {/* {console.log(con.response, con.uid, 'message history')} */}
                  {con && con.response?.responseType && con.response?.responseType === "TextOnly" && (
                    <TextOnly convoId={con.uid} index={index} responseType={con.response.responseType} text={con.response.responseText} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick = {false} onValueChange={handleValueChange} effects={conList.length-1 === index && stopStreaming ? true: false} isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}
                    />
                  )}
                  {con && con.response?.responseType && con.response?.responseType === "BrandingInformation" && 
                    <BrandingCard convoId={con.uid} index={index} text={con.response.responseText} branding={con.response.branding} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick = {false}  onValueChange={handleValueChange} effects={conList.length-1 === index && stopStreaming ? true: false} isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                  }
                  {con && con.response?.responseType && con.response?.responseType === "ProductListing" && (
                    <SingleCategoryList  convoId={con.uid} index={index}  responseTitle={con.response.responseTitle} text={con.response.responseText} outfitUrl={con.response.outfitUrl} branding={con.response.branding} products={con.response.products} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick = {false}  onValueChange={handleValueChange} effects={conList.length-1 === index  && stopStreaming? true: false} isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered} />

                  )}
                  {con && con.response?.responseType === "ProductDetail" && (
                    <SpecificProductDetails convoId={con.uid}  index={index} responseTitle={con.response.responseTitle} text={con.response.responseText} branding={con.response.branding} product={con.response.products} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick = {false}  onValueChange={handleValueChange} effects={conList.length-1 === index && stopStreaming ? true: false} isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                  )}
                  {/* Multi Outfit Template */}
                   {con && con.response?.responseType === "ProductCollectionListing" && (
                    <div>
                      <MultiCatTemplate  convoId={con.uid} index={index} responseType={con.response.responseType} responseTitle={con.response.responseTitle} text={con.response.responseText} branding={con.response.branding} products={con.response.productCollections} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick = {false}  onValueChange={handleValueChange} effects={conList.length-1 === index && stopStreaming ? true: false}/>
                    </div>
                  )}
                </div>
            }
          </div>
        ))
      }
      {feedBackModal && <ApplicationFeedback/>}
        {/* <div className="mb-5"><ApplicationFeedback/></div>       // Application Feedback Modal */}  
    </div>
  );
}

export default MessageHistory;