import React, { useState,useEffect,useRef,useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import PromptsCarousel from "./PromptCarousel/PromptsCarousel";
import ArchiveCarousel from "./ArchiveCarousel/ArchiveCarousel";
import { uniqueGroupSessionHistory } from "../Services/ApiServices/chatServices";
import { useDispatch, useSelector } from "react-redux";
import { apiError,  conversationList } from "../Services/Redux/Reducers/chatSlice";
import {  loader, stopStreaming,  } from "../Services/Redux/Reducers/commonSlice";
import ApplicationFeedback from "../CommonComponents/ApplicationFeedback";
import OnboardCarousel from "./OnboardingCarousel/OnboardCarousel";
import { isTwoMonthsFromNow } from "../Services/CommonServices/helper";
import {searchBoxVisible } from "../Services/Redux/Reducers/chatSlice";
import { appFeedBackModal, appLevelFeedback } from "../Services/Redux/Reducers/feedBackSlice";
import { userPrompt } from "../Services/Redux/Reducers/userSlice";
 
function Useronboard() {
  // const [isOpen, setIsOpen] = useState(true);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
 
  const buttonRef =useRef(null);
  const inputRef =useRef(null);
  const intervalRef = useRef(null)
 
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] =useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] =useState(true);
  const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
  const [animateState,setAnimateState]=useState(false);
  const [onboardLoading, setOnboardLoading] = useState(false);
  const [promptFade, setPromptFade] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselData,setCarouselData] = useState([]);
  const[isLoading,setIsLoading] = useState(true)
  const [modalDeleteState,setModalDeleteState]  = useState(false)
  // const [currentMsgIndex, setCurrentMsgIndex] = useState(0);
  const [currentMsgIndex, setCurrentMsgIndex] = useState(
    (parseInt(localStorage.getItem('questionIndex')) || 0)
  );
  const [isFocused, setIsFocused] = useState(false);
  const [onPromptOpen, setOnPromptOpen] = useState();
  const [onPromptClose, setOnPromptClose] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [userName, setUserName] = useState("");
  const isMounted = useRef(true);
  const prevIndexRef = useRef(0);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const [showLogo, setShowLogo] = useState(true);
  const [showMobileCarousel, setShowMobileCarousel] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  // const [searchBoxVisible, setSearchBoxVisible] = useState(true);
  const [promptsVisible, setPromptsVisible] = useState(false);
  const [isBackFromArchive, setIsBackFromArchive] = useState(false);
  const [onboardCarouselFadeOut,setOnboardCarouselFadeOut ] = useState(false);
  const isSearchBoxVisible =  useSelector((state) => state.chat.searchBoxVisible);

  const isUserCheck = useSelector((state) => state.chat.userCheck)

  useEffect(() => {
    dispatch(conversationList([]));
    dispatch(userPrompt(''));
    dispatch(stopStreaming(false));
    dispatch(loader(false));
    const handleResize = () => {
      //  const mobileView = window.innerWidth <= 768;
      const mobileView = window.innerWidth <= 576;
      const focused = window.innerHeight < window.screen.height * 0.7;
      setIsKeyBoardOpen(focused);
      setIsMobile(mobileView); // Adjust the width as needed
      if (!mobileView) {
        // Reset to initial state when switching to desktop view
        setShowLogo(true);
        setShowMobileCarousel(false);
        setBlurBackground(false);
        // setSearchBoxVisible(true);
        dispatch(searchBoxVisible(true));
        setPromptsVisible(true);
      }
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {    
    if((carouselData?.length >= 0 && carouselData.isUserExist) ){
        setShowLogo(true);
        setShowMobileCarousel(false);
        setBlurBackground(false);
        dispatch(searchBoxVisible(true));
        setPromptsVisible(true);
    }
    else if (isMobile && carouselData?.length === 0 && !carouselData.isUserExist && !isUserCheck ) {
      dispatch(searchBoxVisible(false));
      setPromptsVisible(false);
      const timer = setTimeout(() => {
        setShowLogo(false);
        setTimeout(() => {
          setBlurBackground(true);
          setTimeout(() => {
            setShowMobileCarousel(true);
          }, 1800);
        }, 1000);
      }, 2500);
 
      return () => clearTimeout(timer);
    } 
    else{
      setShowLogo(true);
        setShowMobileCarousel(false);
        setBlurBackground(false);
        dispatch(searchBoxVisible(true));
        setPromptsVisible(true);
    }
 
  }, [isMobile, carouselData]);

  const handleSkip=()=>{
    // setSearchBoxVisible(true);
    dispatch(searchBoxVisible(true));
    setPromptsVisible(true);
  }

  const handleGetStartedClick = () => {
    setShowLogo(true);
    // setShowMobileCarousel(false);
    setBlurBackground(false);
    setTimeout(()=>{setShowMobileCarousel(false);},500);
    setIsBackFromArchive(true);
    setOnboardCarouselFadeOut(true);
  };
 
  const feedback_day_check = useSelector((state) => state.feedBack.feedBackCheck)
  const modalFlag = useSelector((state) => state.feedBack.appFeedBackModal)
  const conList = useSelector((state) => state.chat.conversationList);
  const summaryData = useSelector((state) => state.feedBack.appLevelFeedback)
  // console.log('modalFlag',modalFlag);

  useEffect(() => {
    if(summaryData && summaryData.length >= 1 && summaryData && summaryData?.feedBack_Length === 0){
      dispatch(appFeedBackModal(true))
    }
    // else if(summaryData && summaryData.length >= 1 && feedback_day_check){
    else if(summaryData && summaryData.length >= 1 && isTwoMonthsFromNow(summaryData.feedBackData && summaryData.feedBackData[0].requestDateTime)){
      dispatch(appFeedBackModal(true))
    }
    
  },[summaryData])

  // onboarding app level feedback modal 
  
  //setting the sesssionId empty in session storage
  sessionStorage.removeItem("persist_session");
 
  const inputMsg = ["What should I wear to a winter wedding?","Do you have any large Polo shirts in white?","What gift should I get my significant other?","What's the origin of the Polo Bear?"]
  
  const slides = [{title1 : 'FOR AN OCCASION' , title2:'What would you recommend I wear for a summer', title2_1: 'wedding in Milan?',ques:'What would you recommend I wear for a summer wedding in Milan?'},
  {title1 : 'HOW TO WEAR' , title2:'Show me how to tie the perfect tie knot.',ques:'Show me how to tie the perfect tie knot.'},
  {title1 : 'GIFTING ADVICE' , title2:'What should I gift my mother for Mother’s Day?',ques:'What should I gift my mother for Mother’s Day?'}]


  // for slider data function
  const storeMessage = async (accounts) => {
    try{
    uniqueGroupSessionHistory(accounts[0].username,(response) => { // Chat History storing the messages      
    setCarouselData(response.data);
    dispatch(appLevelFeedback(response.data))
    setIsLoading(false)
    }, (err) => {
      dispatch(apiError(err.message));
      dispatch(loader(false));
    });
  }
 catch(err){
  console.log('Error Message Occured in Group Session history Api call',err.message);
}
  }

  useEffect(() => {
    storeMessage(accounts)
  },[modalDeleteState])


  useEffect(()=> {
    if(accounts.length !== 0) {
        if(accounts[0].name.includes(',')){
            let firstname = accounts[0].name.split(',')[1];
            firstname = firstname.split(' ')[1];
            setUserName(firstname);
        } else {
            let firstname = accounts[0].name;
            setUserName(firstname);
        }
        // storeMessage(accounts);
    }
}, [accounts])

  const handleFocus=()=>{  
    setIsPlaceHolderVisible(false);  //true
    //clearInterval(intervalRef.current);
    setIsFocused(true);   
    setIsSendButtonDisabled(false); 
  }
 
  const handleInputBlur =()=>{
    setIsFocused(false);
    //setCurrentMsgIndex(0);
    //clearInterval(intervalRef.current);
    setIsSendButtonDisabled(true);
  }

//   useEffect(()=>{
//     isMounted.current=true;
//      return () => isMounted.current=false;
//     },[]);

//   const interval = 6000;
//   useEffect(()=>{
//     if(isMounted.current && isAnimating && !showCarousel){
//     const intervalId = setInterval(()=>{
//       setCurrentMsgIndex((prevIndex)=>(prevIndex < inputMsg.length -1 ? prevIndex +1 : 0))
//     },interval);
 
   
//     return () => clearInterval(intervalId);
//   }
// },[isMounted,isAnimating,showCarousel,inputMsg]);
 
 useEffect(()=>{
        prevIndexRef.current = currentMsgIndex;
 },[currentMsgIndex])

useEffect(() => {
  isMounted.current = true;
  if (isAnimating && !showCarousel) {
    startMsgAnimation();
  }
  // } else {
  //   clearInterval(intervalRef.current);
  // }
  return () => {
    clearInterval(intervalRef.current);
    isMounted.current = false;
  };
}, [isAnimating, showCarousel, inputMsg.length]);

const startMsgAnimation = () => {
  clearInterval(intervalRef.current);
  intervalRef.current = setInterval(() => {
    if(isMounted.current) {
      setCurrentMsgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % inputMsg.length;
        localStorage.setItem('questionIndex', newIndex);
        return newIndex;
      });
    }
  }, 6000);
};

  const closeCarousel = () => {
    setIsSendButtonDisabled(true);
    setPromptFade(false);
    setTimeout(() => {
      setShowCarousel(false);
    }, 830);
  };
 
  const onHandleInputChange =(e)=>{
    const inputValue = e.target.value
    setInput(inputValue)
    setIsSendButtonDisabled(inputValue.trim()==='');
  }
   
  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPlaceHolderVisible(input === '' || !input);
    }
    else if(buttonRef.current && !buttonRef.current.contains(event.target)){
    setIsPlaceHolderVisible(input === '' || !input)
    }
  };
 
  const onHandAnimation=()=>{
    dispatch(stopStreaming(true))
    dispatch(conversationList([]));
    setAnimateState(true);
    setOnboardLoading(true);
    dispatch(loader(false))
    dispatch(userPrompt(input));
     setTimeout(()=>{
      navigate("/chat");
    },2000)
  }

  useEffect(() => {
    const handleClickOutside=(event)=>{
     if(inputRef.current && !inputRef.current.contains(event.target)){
       setIsPlaceHolderVisible(true);
       startMsgAnimation();
      //  setIsSendButtonDisabled(true);
      //  if(!input){
      //   setIsSendButtonDisabled(false); //change
       
      //  }
     }
    } ;
      document.addEventListener("mousedown", handleClickOutside);
     return () => {
      document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []);


   const handleCaroselAnimate =(ques)=>{
    setInput(ques);                          
    // closeCarousel();
    setIsPlaceHolderVisible(false);
    setIsSendButtonDisabled(false);
    // setOnboardLoading(false)
    dispatch(loader(false))
    // setOnPromptOpen(false);
    // setOnPromptClose(true);
    // onHandAnimation();
    const element= document.getElementById("para");
    if(element){
      element.focus();
    }
}

useEffect(() => {
  setTimeout(() => {
    if(document.getElementById("next")) {
      document.getElementById("next").click()
    }
  }, 3000);
 }, [showCarousel])

  return (  
    <div>
    <div className={`container container-sm container-xs container-md container-xl container-xxl container-fluid useronboard  mx-auto py-5  img-fluid px-0 ${animateState ? 'backgroundOut' : '' } ${blurBackground ? 'blur' : ''}` }>
    <div className={`${isBackFromArchive ? 'animateOnboardCarousel':""}`}>
       <div class="row align-items-center justify-content-center">
       <div className={`logo px-0 ${showLogo ? 'fade-in-logo' : 'fade-out-logo'} ${isKeyBoardOpen ? 'focused' : ''}`}>
          <img
             src="./static/assets/AskRalphBetaLogo.svg"
             class="img-fluid logo-img"
             width="400"
            height="100.998px"
            alt="no img"
             />
          </div>
          {isSearchBoxVisible && !isLoading ? ( 
            <div className={`chatbox box-msg d-flex justify-content-center align-items-center ${!isBackFromArchive && 'animateSearch' }  ${animateState ? 'animateSearchOut' : ''} ${isKeyBoardOpen ? 'focused' : ''}`}>
             <input value={input} ref={inputRef} className="form-control msg" type="text" id="para"                   
                    // autoFocus
                    onFocus={handleFocus}
                    onBlur={handleInputBlur}
                    onChange={(e) => onHandleInputChange(e)}
                    onKeyDown={(e) => {
                       if (e.code === "Enter") {
                         e.preventDefault()
                         onHandAnimation() 
                         dispatch(stopStreaming(true))                        
                       }
                    }}
              />
             
            {isPlaceHolderVisible && !input &&  
            <div className="d-block align-middle">
           
           <span onClick={() => {document.getElementById("para").focus()}} className={`leJeuneDeck col-12 message-fade ${currentMsgIndex ? 'animation-msg':''}`}>{inputMsg[currentMsgIndex]}</span>
              </div>
            }
               
          {!onboardLoading ? (  
            <button
              name="submitMessage"
              onClick={() => {onHandAnimation()}}
              className={`arrow-dist send-arrow-position ${
                isSendButtonDisabled ? "disabled" : "enabled"
              } `}
              disabled={!input || (isFocused || input.trim() === '') && isSendButtonDisabled}
            >
              <img
                src={
                  isSendButtonDisabled
                    ? "/static/assets/arrowlong.svg"
                    : "/static/assets/bluearrow.svg"
                }
                height="36px"
                width="36px"
                alt="arrow-img"
                className="arrow_long"
              />
            </button>
          ) : (
            <div className="onboard-loader" role="status"></div>
          )}
               
          </div>):<></>} 
          </div> 
          {/* } */}
          <br/>
 
          {showLogo && 
          <div className="row d-grid mx-0">
                            
          <div className={`container-fluid userOnboardContainer col-12 col-sm-10 col-xl-9 px-xl-4 px-xxl-5  ${animateState && 'carousel-out'}`}>
            {!isLoading && carouselData && carouselData.length === 0 && (
              <>
              {promptsVisible ? (
              <PromptsCarousel
                handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
                isBackFromArchive={isBackFromArchive}
              />
            ):<></>}
            </>
            )} 
            {!isLoading && carouselData && carouselData.length > 0 && (
              <ArchiveCarousel
                handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
                data={carouselData}
                setModalDeleteState={setModalDeleteState}
                inputRef={inputRef}
                isBackFromArchive={isBackFromArchive}
              /> 
            )} 
          </div>
          </div> }
        {/* </div> */}
        {modalFlag && <ApplicationFeedback/>}
    </div>
    </div>
    {showMobileCarousel &&  !isLoading && carouselData && carouselData.length === 0 &&  !carouselData.isUserExist &&
      <div className={`opacityCarousel  positionCarouselOnboard ${onboardCarouselFadeOut ? 'opacityImagesCarousel' : ''}`}>
          <OnboardCarousel handleGetStartedClick={handleGetStartedClick} handleSkip={handleSkip}/>
      </div>
    }
    </div>
  );
}
 
export default Useronboard;    