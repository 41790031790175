import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
      isLoader: false,
      sessionId: null,
      isSessionChange :false,
      isResponseStopped:false,
      stopStreaming:true,
      visibleTooltip: null,
      showDeleteModal: false,
      faqFade : false,
    },
    reducers: {
      loader: (state, action) => {
        state.isLoader = action.payload
      },
      session: (state, action) => {
        state.sessionId = action.payload        
      },
      sessionChange: (state, action) => {
        state.isSessionChange = action.payload       
      },
      isResponseStopped: (state, action) => {
        state.isResponseStopped = action.payload      
      },
      stopStreaming: (state, action) => {
        state.stopStreaming = action.payload
      },
      visibleTooltip: (state, action) => {
        state.visibleTooltip = action.payload
      },
      showDeleteModal: (state, action) => {
        state.showDeleteModal = action.payload
      },
      faqFade: (state, action) => {
        state.faqFade = action.payload
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const {  loader,session,sessionChange, isResponseStopped, stopStreaming, visibleTooltip,showDeleteModal,faqFade } = commonSlice.actions
  
  export default commonSlice.reducer
  
  
  