import { createSlice } from '@reduxjs/toolkit'
 
export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
      conversationList: [],
      storeChat: false,
      apiError: false,
      stopResponse: false,
      sessionHistory: [],
      groupSessionHistory : [],
      deleteChat : false,
      searchBoxVisible : true,
      userCheck : false
    },
    reducers: {
      conversationList: (state, action) => {
       state.conversationList = [...action.payload]
      },
      storeChat: (state, action) => {
        state.storeChat = action.payload
      },
      sessionHistory: (state, action) => {
        state.sessionHistory = action.payload
      },
      groupSessionHistory: (state, action) => {
        state.groupSessionHistory = action.payload
      },
      deleteChat: (state, action) => {
        state.deleteChat = action.payload
      },
      apiError: (state, action) => {
        state.apiError = action.payload
      },
      stopResponse: (state, action) => {
        state.stopResponse = action.payload
      },
      searchBoxVisible: (state, action) => {
        state.searchBoxVisible = action.payload
      },
      userCheck: (state, action) => {
        state.userCheck = action.payload
      },
    },
  })
 
  // Action creators are generated for each case reducer function
  export const { conversationList, storeChat, apiError,  stopResponse, sessionHistory,groupSessionHistory, deleteChat,  searchBoxVisible,userCheck } = chatSlice.actions
 
  export default chatSlice.reducer
 