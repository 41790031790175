import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
      loggedUserDetails: null,
      userIp: "",
      userPrompt: "",
    },
    reducers: {
      loggedUserDetails: (state, action) => {
        state.loggedUserDetails = action.payload
      },
      userIp: (state, action) => {
        state.userIp = action.payload       
      },
      userPrompt: (state, action) => {
        state.userPrompt = action.payload      
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { loggedUserDetails,  userIp, userPrompt } = userSlice.actions
  
  export default userSlice.reducer
  
  
  