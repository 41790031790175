import React from 'react';
 
const splitText = (text) => {
  const words = text.split(' ');
  if (words.length === 2) {
    return [words[0], words[1]];
  }
 
  const lines = [];
  let currentLine = '';
 
  words.forEach((word) => {
    if ((currentLine + word).length <= 13) {
      currentLine += (currentLine ? ' ' : '') + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });
 
  if (currentLine) {
    lines.push(currentLine);
  }
 
  return lines;
};
 
const TextWrapper = ({ text }) => {
    // const lines = splitWords(words, maxChars);
    const lines = splitText(text);
 
    return(
        <div>
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
    )
 
};
 
export default TextWrapper