import { createSlice } from '@reduxjs/toolkit'
 
export const feedbackSlice = createSlice({
    name: 'feedBack',
    initialState: {
      likeStatus: false,
      likeApiError: false,
      appLevelFeedback : [],
      showLikeDisLikeState : true,
      appFeedBackModal : false,
      feedBackCheck : false,
    },
    reducers: {
      likeStatus: (state, action) => {
        state.likeStatus = action.payload
      },
      likeApiError: (state, action) => {
        state.likeApiError = action.payload
      },
      appLevelFeedback: (state, action) => {
        state.appLevelFeedback =  action.payload
      },
      showLikeDisLikeState: (state, action) => {
        state.showLikeDisLikeState = action.payload
      },
      appFeedBackModal: (state, action) => {
        state.appFeedBackModal = action.payload
      },
      feedBackCheck: (state, action) => {
        state.feedBackCheck = action.payload
      },
    },
  })
 
  // Action creators are generated for each case reducer function
  export const {likeStatus, likeApiError, appLevelFeedback, showLikeDisLikeState, appFeedBackModal, feedBackCheck} = feedbackSlice.actions
 
  export default feedbackSlice.reducer